

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');


*{
    margin:0;
    padding: 0;
    box-sizing: border-box !important;
}


body {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px !important;
    color: #333;
    line-height: 20px;
    text-align: left;
}

.swiper-slide img{
    min-width:100%;
    height: 500px;

}

.swiper-button-next,.swiper-button-prev{
    display: none !important;
}

.slide-image{
    background-color: red;
    padding: 10px;
}

.container-box{
    margin-top: 50px !important;

}
.banner-img{
    width: 100%;
}


.gb-color{
   background-color: #ceb23d
}


.submenu{
    margin: 0;
    padding: 0;
    margin-top: 10px;
    background-color: #000;
    position: absolute;
    top: 35px;
    left: 0;
    list-style: none;
    z-index: 9999;
    display: none;
    border-right: none !important;
    
}

.submenu li a:hover{
    display: block;
    color: #fff;
}

.submenu li a{
    display: inline-block;
    font-size: 17px;
    text-decoration: none;
    line-height: 45px;
    color: #fff;
    padding: 0px 30px;
    font-weight: 500;
    text-transform: capitalize;
    white-space: nowrap;
}

.submenu li a:hover{
    background-color:#eebf00 ;
    border-bottom: #44aff5 1px solid;
}

.main-nav-list li {
    position: relative;
}

.main-nav-list li:hover .submenu {
    display: block;
}