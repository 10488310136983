.contact-banner{
    margin: 0;
    padding: 0;
}

.contact-banner-img{
    width: 100%;
    height: auto;
}

.contact-box{
    margin-top: 50px !important;
}

.contact-heading{
    color: rgb(206 178 61);
}

.info-box{
    margin-top:30px;
    
}

.info-box a{
    text-decoration: none;
    color: #2E2E2E;
}

.info-box a:hover{
    color: #000;
}

.contact-detail-box{
    display: flex;
    font-size: 17px;
    align-items: center;
    gap:4px;

}

.contac-timing{
    display: flex;
    gap:10px
}
.contact-form{
    padding: 10px;
}

.contact-form input{
    margin-bottom: 20px;
    border-radius: none !important;
}

.contact-form .form-control{
   border-radius: 2px;
}
 .contact-btn{
    padding: 10px 50px;
    border:none;
    background-color:#CEB23C;
    color: #fff;
    
}

.contact-btn:hover {
    background: #014c74 !important;
}