.about-banner{
   margin: 0;
   padding: 0;
}

.about-banner-img{
    width: 100%;
    
}

.about-box{
    margin-top: 50px;
}

.about-heading{
    color: #000 !important;
    font-size: 28px !important;
    padding-bottom: 0px;
    text-align: center;
    font-weight: 300;
}

.about-subheading{
    color: #ceb23d;
    display: block;
    text-transform: uppercase;
    text-align: center;
}

.about-desc{
    margin-top: 20px;
    line-height: 1.6;
    text-align: center;
    text-align: justify;
}

.about-box h2{
    font-size: 22px;
    color: #333;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 400;
}

.company-desc{
    line-height: 20px;
    text-align: justify
}

.customer-expect-heading{

    font-size: 22px;
    color: #333;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 400;
}

.sustomer-axpect-desc{
    text-align: justify;
}