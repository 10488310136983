.saving-heading{
    font-size: 22px;
    color: #333;
    text-align: left;
    font-weight: 500;
    margin-bottom: 10px
}
.saving-desc{
    line-height: 20px;
    text-align: justify;
    margin: 0 0 10px
}

.saving-box{
    background-color: #ceb23d;
    border-radius: 10px;
    padding: 70px 0;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 15px;
}




.tbl-heading{
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
}

.deposit-tbl{
    width: 100%;
}

.deposit-tbl th{
    text-align: left !important;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    background: #ceb23d !important;
    color: #fff !important;
}

/* .deposit-tbl tr:nth-child(odd){
    background-color: #FFC0CB;
} */

.depostt-heading{
    color: #ceb23d;
    font-family: "Roboto", sans-serif;
    font-size: 19px;
    line-height: 0;
}

.deposit-desc{
    margin-top: 30px;
    line-height: 20px;
    text-align: justify;
    color: #002e5b;
    /* font-family: "Open Sans", sans-serif;
    font-size: 15px; */
}

.fix-deposit-img{
    width: 200px;
    vertical-align: middle;
}

.clipmakers {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.clipmakers:hover{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition:  .5s;
}



.saving-feature-heading{
    font-size: 22px;
    color: #333;
    text-align: left;
    font-weight: 500;
    margin-bottom: 10px
}

.account-desc{
    line-height: 20px;
    text-align: justify;
    margin: 0 0 10px
}


.daily-heading{
    text-align: center;
    font-size: 31px;
    /* padding-top: 30px; */
}

.daily-deposit-desc{
    margin-top:30px;
    font-size: 42px;
    text-align: center;
    font-weight: 700;
}

.daily-small-info{
    text-align: center !important;
    /* padding-top: 13px; */
    /* padding-bottom: 13px; */

}

.saving-btn{
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        user-select: none;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: #EFEFEF !important;
    
}

.saving-btn:hover{
    color: #333;
    text-decoration: none;
}



.flex-rd-box{
    background-color: #ceb23d;
    border-radius: 10px;
    min-height: 25rem;
    padding: 90px 0;
  
}

.flex-rd-img{
    width: 100%;
    border-radius: 10px;
    min-height: 25rem;
    max-height: 25rem;
    /* padding: 70px 0; */
   
}