.schema-box{
    margin: 50px 0;
}

.schema-heading{
    color: #000 !important;
    font-size: 28px !important;
    padding-bottom: 0px;
    text-align: center;
    font-weight: 300;
    
}

.schema-sub-heading{
    text-align: center;
    color: #ceb23d;
    display: block;
    text-transform: uppercase
}

.schema-fouth-heading{
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    color: #ceb23d;
    /* background-color: red; */
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
}


.other-heading{
    /* background-color: red; */
    text-align: center;
    margin-right: 100px;

}
.schema-img{
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    width: 190px;
    cursor: pointer;
    height: 190px;
    vertical-align: middle;

  
}

.big-shape-img{
    width: 300px;
    height: 300px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.schema-img:hover {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: .5s;
}


/* ********************** */
/*  OTHER STYLE */
/* ************* */

/* .Schemes-box {
    transition: transform .5s;
    text-align: center;
    border-radius: 2px;
    margin-bottom: 15px;
}

.clipmakers {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
} */
/* img {
    vertical-align: middle;
} */
