.right-box {
    font-size: 30px;
}

.logo-img{
    width: 278px;
}
.right-box a:link,
.right-box a:visited {
    text-align: left;
    color: #000000;
    margin: 0;
    height: auto;
    width: 183px;
    text-decoration: none;
    font-size: 17px;
}


/* **************************** */
/*  NAVIGATION *
/* **************************** */


.navbg {
    background-color: #ceb23d !important;

}



.main-nav-list {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    /* gap: 1.2rem; */
    background-color: #ceb23d;

}

.main-nav-link:link,
.main-nav-link:visited {
    display: block;
    font-size: 17px;
    height: 45px;
    text-decoration: none !important;
    line-height: 45px;
    color: #fff;
    padding: 0px 30px;
    font-weight: 400;
    text-transform: capitalize;

}

.main-nav-link:hover,
.main-nav-link:active {
    background-color: #000000;
    


}

.main-nav-list  > li {
    border-right: 1px solid red;
    height: 100%;

}


.main-nav-list li:last-child {
    border: none
}

.deliverd-imgs img:last-child {
    margin: 0;
}

.menu-icon {
    height: 1.2rem;
    margin-bottom: 4px;
}






 
  