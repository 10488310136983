.about-subheading {
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;
  font-size: 24px;
  color: #000;
}

h6 {
  font-size: 18px !important;
  color: #272727;
  margin-top: 15px;
}

.container {
  max-width: 750px;
  margin: 0 auto;
  padding: 30px !important;
}

p {
  line-height: 20px;
  padding-bottom: 4px;
  padding-top: 4px;
  text-align: justify;
  font: 14px;
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
    padding: 30px 120px !important;
  }
}
