

.online-deposit-heading{
    color: #000 !important;
    font-size: 28px !important;
    padding-bottom: 0px;
    text-align: center;
}

.online-deposit-desc{
    line-height: 2;
    font-size: 18px;
    text-align: center;
    color:#333;
}



.deposit-box{
    padding: 20px;
    /* min-width: 550px;
    max-width: 550px; */
    background-color: #ceb23d;
}


.deposit-img{
    width: 100%;
    
    /* margin-bottom: 30px; */
    /* padding: 20px; */
}
/* .deposit-box p{
    font-size: 17px;
    padding-bottom: 22px;
    padding-top: 10px
} */

.info{
    font-size: 17px;
    padding-top: 10px;

}

.last-l{
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 80px;

}
.bold-info{
    font-size: 19px;
}
.note{
    margin-top: 30px;
    font-size: 18px;
}

.note span{
    color:red;
    font-size: 20px;
}

.deposit-list{
    margin-top: 30px;
    display: flex;
    gap: 100px;
    
    /* align-items: center;
    justify-content: space-between; */

}

.list-heading{
    list-style: none;
    color: #4a4e51;
    font-weight: 600;
   margin-left: -30px;
}
.deposit-list li{
    margin-bottom: 20px;
}

.list-info{
    list-style: none;
}
