
.other-loan-box{
    /* images/other.png */
    text-align: center;
    background-color:hwb(212 8% 35%);
    background-image: url('http://demo11.qrologic.com/content/images/banner-bg.png');
    
}
.other-loan-heading{
    text-align: center;
    color: #fff !important;
    font-weight: 500;
    font-size: 35px !important;
    line-height: 180px;
}

.other-desc{
    color:#000;
    line-height: 20px;
    text-align: justify
}

.other-icon{

}
.loan-box{
    padding: 20px;
    background-color: #ceb23d;
    border-radius: 10px;
    margin-bottom: 30px;
    /* margin-top: 15px; */
}

.featured-heading{
    color: #ceb23d;
    font-size: 20px;
}

.featured-list{
    color: #002e5b;
    font-size: 17px;
}
.featured-list{
    list-style: none;
}
.loan-box h3{
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}

.interest-heading{
    margin-top: 50px;
    text-align: center;
    font-size: 31px;
    padding-top: 30px
}
.percentage{
    font-size: 42px;
    text-align: center;
    font-weight: 700;
    padding-bottom: 45px;
    color: #002e5b;
}

.load-btn{
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.gold-loan-desc{
    font-size: 24px;
    text-align: center;
    padding-top: 30px;
    color: #002e5b;
    margin-top: -20px;
    margin-bottom: -20px;
}