.fix-navigation {
    position: fixed;
    top: 0;
    background-color: #ceb23d;
    width: 100%;
    z-index: 100;

}

.footer_div {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    padding: 30px 0px;

}

.link {
    width: calc(100% / 4 - 30px);
}

.download-link {
    background-color: #282828;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 8px;
    text-decoration: none;
    max-width: 15rem;
    gap: 10px;
    font-size: 14px;
    line-height: 25px;
    padding: 5px;
  }
  
  .download-text {
    display: block;
  }
  
  .get-it-on-text {
    display: block;
    font-size: 14px;
  }
  
  .app-name {
    white-space: nowrap;
    font-size: 18px;
    font-weight: 700;
  }
  
@media screen and (max-width:980px) {
    .link {
        width: calc(100% / 2 - 30px);
    }


}

@media screen and (max-width:600px) {
    .link {
        width: 100%
    }
    .footer_div {
    gap: 15px;
    padding: 15px 0px;
    }

}