

/* **************************** */
/*  MEDIA QUERY *
/* **************************** */



.red {
    background-color: red;
    height: 300px;
}


.blue {
    background-color: blue;
    height: 10px;
}

.mobile-menu {
    display: none;
}


@media only screen and (max-width: 1100px) {
    .hide-nav {
        display: none;
    }

    .navbg {
        background-color: #fff !important;
    }

    .main-nav-list {
        flex-direction: column;
        background-color: #fff;
        margin-bottom: 20px;

    }

    .main-nav-list li {
        border-right: none;
        background-color: #ceb23d;
        width: 100%;
        margin: 10px;
        text-align: center;
        /* gap: 40px !important; */

    }

    .desktop-header {
        display: none !important;
    }

    .none-div {
        display: flex;

    }

    .mobile-menu {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 30px;
    }


    .menu-toggle {
        font-size: 50px;
        margin-top: -20px;


    }

    .menu-toggle span {
        cursor: pointer;
    }
}

@media screen and (max-width:780px) {
    .link {
        margin-bottom: 20px;
    }
    .container-box{
        margin-top: 20px !important;
    }
}