


.welcome-box{
    margin-top: 70px;
    background-color: #ceb23d;
}

.welcome-img-box{
    margin:50px 0;
   
}

.welcome-img-box img{
    width: 100%;
    border-radius: 10px;
}

.welcome-content-box{
    margin:50px 0;
}

.welcome-heading{
    font-size: 29px;
    color: #000;
    font-weight: 600;
    margin-bottom: 20px;
}

.welcome-desc{
   padding: 10px;
   font-weight: bold;
   color: #000;
   overflow: hidden;
}