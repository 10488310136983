

.footer-wrapper{
    /* position: fixed;
    bottom: 0;
    background-color: #000;
    color:#fff; */
  
   
    margin-top: 30px;
    background: #01001f;
    padding-top: 15px;
    border-top: #fff 1px solid;
     outline: #ecca68 5px solid;


}

.footer-wrapper h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px !important;
    text-transform: capitalize;
}

.footer-wrapper .link a {
    color: #fff;
    text-decoration: none;
    display: block;
    font-size: 14px;
    line-height: 25px;
    text-align: left;
    -webkit-transition: padding 0.5s ease ;
}

.footer-wrapper .link a:hover {
    color: #ccc;
    text-decoration: underline;
    text-decoration: none;
    padding-left: 10px;
}

.footer-wrapper p {
    color: #fff;
    font-size: 14px;
    line-height: 25px;
}

.footer-wrapper .footer {
    text-align: center;
    color: #fff;
    margin-top: 20px;
    border-top: #2e6d8e 1px solid;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 0;
}