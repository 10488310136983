.our-services {
    background: #f7f5f3;
    line-height: 20px;
    margin-top: 25px;
    text-align: left;
    font-size: 12px;
    padding-bottom: 10px;

}

.service-heading {
    background: #ceb23d;
    line-height: 35px;
    font-size: 19px;
    color: #fff;
    padding: 0 10px;
    margin-top: 0;
    font-weight: 400;
}

.service-box {
    display: flex;
    padding: 5px 20px;
    gap: 10px;
    /* align-items: center; */
    justify-content: center;
}

.service-img {
    margin-right: 10px;
    margin-bottom: 45px;
    height: 70px;
}

.service-description {
    font-weight: 700;
}

.read-more {
    display: flex;
    justify-content: space-between;

}

.service-info {

    margin-right: 20px;
}

.service-info a {

    color: #21abc3 !important;
}

.read-more>a {
    color: #21abc3 !important;
    line-height: 26px;
    font-weight: bold;
    text-decoration: none;
}

.read-more>a:hover {
    color: #21c3ab !important;
    line-height: 26px;
    font-weight: bold;
    text-decoration: underline;
}

